<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="branchName" @md-selected="getBranchSelected" :md-options="branchs" @md-changed="getBranchs"  @md-opened="getBranchs" :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                    <label>Chi nhánh</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.branchName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                                </md-autocomplete>
                                <md-button @click="openBranch()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-12 m-12 c-12">
                            <md-field>
                                <label for="tempType">Thiết lập cho</label>
                                <md-select v-model="entity.tempType">
                                    <md-option :value="1">Phí điều xe</md-option>
                                    <md-option :value="2">Phí làm hàng</md-option>
                                    <md-option :value="3">Phí vendor</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-12 m-12 c-6">
                            <div class="form-control">
                                <md-autocomplete v-model="feeName" @md-selected="getFeeSelected" :md-options="fees" @md-changed="getFees" @md-opened="getFees" :class="{'md-invalid': submitted && $v.entity.feeId.$error }">
                                    <label>Tên phí</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.feeName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.feeId.isSelected">Vui lòng chọn loại phí</span>
                                </md-autocomplete>
                                <md-button @click="openFee()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-12 m-12 c-12">
                            <md-field>
                                <label for="type">Loại phí</label>
                                <md-select v-model="entity.type" name="type" id="type">
                                    <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-12 m-12 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.sort.$error }">
                                <label for="sort">Số thứ tự</label>
                                <md-input name="sort" v-mask="'##'" v-model="entity.sort"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.sort.required">Vui lòng nhập số thứ tự</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-checkbox v-if="id == 0" v-model="saveAndCreate" class="md-primary">Lưu và tạo mới</md-checkbox>
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
        <feeList ref="feeList" title="Phí làm hàng" @close="closeFee"/>
        <branchList ref="branchList" @close="closeBranch"/>
    </md-dialog>
</template>

<script>
    import branchService from '../../../api/branchService';
    import settingTempFeeService from '../../../api/settingTempFeeService';
    import feeService from '../../../api/feeService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import feeList from '../../../components/popup/_FeeList.vue';
    import branchList from '../../../components/popup/_BranchList.vue';

    export default ({
        components: {
            feeList,
            branchList,
        },
        metaInfo: {
            title: 'Thêm/Cập nhật thiết lập template phí'
        },
        data() {
            return {
                showDialog: false,
                title: '',
                types: common.typeFee,
                id: 0,
                submitted: false,
                entity: { id: 0, tempType: 1, branchId: 0, feeId: 0, type: 1, sort: 1 },
                saveAndCreate: false,
                feeName: '',
                fees: [],
                branchName: '',
                branchs: [],
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(id){
                this.id = id;
                if(id == 0){
                    this.title = 'Thêm mới thiết lập template phí';
                    this.branchName = '';
                    this.feeName = '';
                    this.entity = { id: 0, tempType: 1, branchId: 0, feeId: 0, type: 1, sort: 1 };
                }
                else {
                    this.saveAndCreate = false;
                    this.title = 'Cập nhật thiết lập template phí';
                    this.getById();
                }
                this.submitted = false;
                this.showDialog = true;
            },

            //Branch
            closeBranch(item){
                this.branchName = item.branchName;
                this.entity.branch = item;
                this.entity.branchId = item.id;
                this.$refs.branchList.close();
            },

            openBranch(){
                this.$refs.branchList.open();
            },

            getBranchSelected(val){
                this.entity.branchId = val.id;
                this.branchName = val.branchName;
                this.entity.branch = val;
            },
            
            getBranchs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                branchService.filter(search).then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            close(){
                this.showDialog = false;
            },

            //Fee
            closeFee(item){
                this.feeName = item.feeName;
                this.entity.feeId = item.id;
                this.$refs.feeList.close();
            },

            getFeeSelected(val){
                this.entity.feeId = val.id;
                this.feeName = val.feeName;
            },

            openFee(){
                this.$refs.feeList.open(0);
            },

            getFees(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                feeService.getFees(search).then((response) => {
                    if(response.statusCode == 200){
                        this.fees = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                settingTempFeeService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$emit('close', this.saveAndCreate);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                settingTempFeeService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('close', this.saveAndCreate);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                settingTempFeeService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.branchName = response.data.branch.branchName;
                        this.feeName = response.data.fee.feeName;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
            back(){
                this.$router.push('/fee');
            }
        },
        watch: { 
            feeName: function (val){
                if(val == ''){
                    this.entity.feeId = 0;
                }
            },
            branchName: function (val) { 
                if(val == ''){
                    this.entity.branchId = 0;
                }
            },
        },
        validations: {
            entity: {
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                feeId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                sort: {
                    required
                }
            }
        }
     })

</script>


<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 600px;
        height: 500px;
        max-width: 600px;
    }
    .md-scrollbar {
        height: 500px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>