<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách thiết lập phí làm hàng</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="getData()" @click="getData()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label>Thiết lập cho</label>
                                <md-select v-model="search.type">
                                    <md-option :value="1">Phí điều xe</md-option>
                                    <md-option :value="2">Phí làm hàng</md-option>
                                    <md-option :value="3">Phí vendor</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:100px;">#</th>
                                <th class="left" style="width:25%;">Chi nhánh</th>
                                <th class="left" style="width:25%;">Tên Phí</th>
                                <th class="left" style="width:20%;">Loại phí</th>
                                <th class="center" style="width:10%;">Thứ tự</th>
                                <th style="width:15%;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && data.length > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="left">{{item.branch.branchName}}</td>
                                <td class="left">{{item.fee.feeName}}</td>
                                <td>
                                    {{ getFeeType(item.type) }}
                                </td>
                                <td class="center">{{item.sort}}</td>
                                <td class="action">
                                    <md-button @click="edit(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
                                    <md-button v-on:click="confirmDelete(item.id)" class="md-fab md-mini md-plain">
                                        <md-icon>clear</md-icon>
                                        <md-tooltip>Xóa</md-tooltip>
                                    </md-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <crudForm ref="crudForm" @close="closeCrud"/>
        <feeList ref="feeList" title="Phí làm hàng" @close="closeFee"/>
    </div>
</template>
<script>
    import crudForm from './Crud.vue';
    import settingTempFeeService from '@/api/settingTempFeeService';
    import branchService from '../../../api/branchService';
    import feeService from '../../../api/feeService';
    import messageBox from '@/utils/messageBox';
    import { mapActions } from 'vuex';
    import feeList from '../../../components/popup/_FeeList.vue';

    export default {
        components: {
            crudForm,
            feeList
        },
        metaInfo: {
            title: 'Danh sách thiết lập template phí'
        },
        data() {
            return {
                loadding: false,
                data: [],
                id: 0,
                search: { branchId: 0, type: 1 },
                branchs: [],
                feeName: '',
                fees: [],
            }
        },
        created(){
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Fee
            closeFee(item){
                this.feeName = item.feeName;
                this.search.feeId = item.id;
                this.$refs.feeList.close();
            },

            getFeeSelected(val){
                this.search.feeId = val.id;
                this.feeName = val.feeName;
            },

            openFee(){
                this.$refs.feeList.open(0);
            },

            getFees(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                feeService.getFees(search).then((response) => {
                    if(response.statusCode == 200){
                        this.fees = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.getData();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            closeCrud(action){
                this.getData();
                if(!action){
                    this.$refs.crudForm.close();
                }
            },

            getData(){
                this.loadding = true;
                settingTempFeeService.getAll(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                settingTempFeeService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.getData();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(obj){
                this.$refs.crudForm.open(obj.id);
            },

            add(){
                this.$refs.crudForm.open(0);
            },
        },
        watch: {
            'search.branchId': function () {
                this.getData();
            },
            'search.type': function () {
                this.getData();
            },
        }
    }

</script>
